<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong class="mr-4">Roles Management</strong>
            <a-button
              class="btn btn-outline-primary"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
          :columns="columns"
          :dataSource="data"
          size="small"
          :pagination="{
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span v-if="column.dataIndex === 'name'">
                {{ text }}
              </span>
              <span v-else>
                {{ text }}
              </span>
            </template>
          </template>
          <template slot="action" slot-scope="text, record, index">
            <a-icon
              type="edit"
              class="text-warning"
              @click="sendToForm('Update', record, record.id)"
              />
            <a-divider type="vertical" v-if="index !== 0" />
            <a-icon
              type="delete"
              class="text-danger"
              @click="showDeleteConfirm(record)"
              v-if="index !== 0"
              />
            <a-divider type="vertical" v-if="index !== 0" />
          </template>
          <span slot="privileges" slot-scope="text, record">
            {{ findData(record) }}
          </span>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="visible"
      title="Form Role"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submitRole()"
        >
          Submit
        </a-button>
      </template>
      <a-input class="" v-model="input.name" placeholder="Masukan nama role" v-on:keyup.native.enter="submitRole()"></a-input>
      <a-select v-model="input.canReadAll" class="mt-2" style="width: 200px;">
        <a-select-option value="0">Tidak bisa read/write</a-select-option>
        <a-select-option value="1">Bisa read/write data sendiri</a-select-option>
        <a-select-option value="2">Bisa read/write semua data</a-select-option>
      </a-select>
      <!-- <a-radio-group v-model="input.canReadAll" class="mt-2">
        <a-radio :value="1">Can read/write all data</a-radio>
        <a-radio class="ml-3" :value="0">Only read/write {{ input.name }} role data</a-radio>
      </a-radio-group> -->
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
// import modifiedinput from '@/components/lou/modifyinput'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    // modifiedinput,
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      visible: false,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      action: 'Create',
      input: {
        name: '',
        canReadAll: 0,
      },
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Name',
          dataIndex: 'title',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.title.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Akses',
          dataIndex: 'privileges',
          scopedSlots: { customRender: 'privileges' },
        },
      ],
      canreadArr: [
        {
          value: 0,
          text: 'Tidak bisa read/write',
        },
        {
          value: 1,
          text: 'Bisa read/write data sendiri',
        },
        {
          value: 2,
          text: 'Bisa read/write semua data',
        },
      ],
      table: 'roles',
      data: [],
      loading: false,
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    findData(record) {
      var ret = this.canreadArr.find(x => x.value === record.privileges)
      // console.log('ret', ret)
      return ret.text
    },
    sendToForm(action, data = {}, id = '') {
      // console.log(data)
      this.showModal(action, data)
    },
    async getData() {
      this.componentKey += 1
      var res = ''
      res = await lou.customUrlGet('roles', false, true)
      this.data = res.data
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('roles?id=' + deldata.id, true, true)
          // console.log('response', response)
          if (!response.isError) {
            this.getData()
            // var target = this.data.findIndex(x => x.id === deldata.id)
            // this.data.splice(target, 1)
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // form
    showModal(action, data) {
      this.visible = true
      if (action === 'Create') {
        this.input.name = ''
        this.input.canReadAll = 0
      } else {
        this.input.name = data.title
        this.input.canReadAll = data.canReadAll
        this.editdata = data
      }
      this.action = action
    },
    async submitRole() {
      if (this.input.name !== '') {
        var response
        var fd = {
          title: this.input.name,
          privileges: parseInt(this.input.canReadAll),
        }
        if (this.action === 'Create') {
          response = await lou.createMaster('roles', fd, true, true)
        } else {
          fd.id = this.editdata.id
          response = await lou.updateMaster('roles', fd, true, true)
        }
        if (!response.isError) {
          this.visible = false
          this.input.name = ''
          this.input.canReadAll = 0
          this.getData()
          // if (this.action === 'Create') {
          //   this.data.push(response.data)
          // } else {
          //   var target = this.data.findIndex(x => x.id === this.editdata.id)
          //   this.data[target] = response.data
          // }
        }
      }
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>
